import { Injectable } from '@angular/core';
import { BizHttp } from '../../../../framework/core/http/BizHttp';
import { UniHttp } from '../../../../framework/core/http/http';
import { SalaryTransaction, SalaryTransactionSupplement } from '../../../unientities';
import { from, Observable } from 'rxjs';
import { RequestMethod } from '@uni-framework/core/http';
import { map, switchMap } from 'rxjs/operators';
import { StatisticsService } from '@app/services/common/statisticsService';

@Injectable({ providedIn: 'root' })
export class SalaryTransactionService extends BizHttp<SalaryTransaction> {
    public supplements: any[] = [];
    public salaryTransactionExpands = [
        'Supplements.WageTypeSupplement',
        'Dimensions',
        'Dimensions.Department',
        'Dimensions.Project',
        'Wagetype',
        'VatType.VatTypePercentages',
        'employment',
        'CarInfo',
        'WageTypeConnectingTransaction',
    ];

    constructor(
        http: UniHttp,
        private statisticsService: StatisticsService,
    ) {
        super(http);
        this.relativeURL = SalaryTransaction.RelativeUrl;
        this.entityType = SalaryTransaction.EntityType;
    }

    getSalaryTransactionsOnPayroll(
        employeeID: number,
        payrollRunID: number,
        ignoreCache: boolean,
    ): Observable<SalaryTransaction[]> {
        return super.GetAll(
            `filter=PayrollRunID eq ${payrollRunID} and EmployeeID eq ${employeeID}&orderBy=IsRecurringPost DESC,SalaryBalanceID DESC,SystemType DESC`,
            [
                'Supplements.WageTypeSupplement',
                'Wagetype',
                'employment',
                'Supplements',
                'Dimensions',
                'Dimensions.Project',
                'Dimensions.Department',
                'Files',
                'VatType.VatTypePercentages',
                'CarInfo',
                'WageTypeConnectingTransaction',
            ],
            ignoreCache,
        );
    }

    getSalaryTransaction(id: number): Observable<SalaryTransaction> {
        return super.Get(id, this.salaryTransactionExpands);
    }

    getReccurringTransaction(id: number): Observable<SalaryTransaction> {
        return this.Get(id, this.salaryTransactionExpands);
    }

    getReccuringTransactions(employeeID: number) {
        return super.GetAll(
            `filter=EmployeeID eq ${employeeID} and IsRecurringPost eq true and PayrollRunID eq 0`,
            this.salaryTransactionExpands,
        );
    }

    getRecurringTransactions(employeeID: number): Observable<SalaryTransaction[]> {
        return this.getReccuringTransactions(employeeID);
    }

    saveMultipleTransactions(transactions: SalaryTransaction[], employeeID): Observable<SalaryTransaction[]> {
        const saveObservables = [];
        transactions
            .filter((transaction) => transaction['_isDirty'] === true)
            .forEach((transaction: SalaryTransaction) => {
                transaction = this.prepareSubEntetiesForSave(this.removeExpandedReadOnlyEntities(transaction));
                saveObservables.push(this.putOrPost(transaction));
            });
        return from(this.runSavingObservablesOneByOne(saveObservables)).pipe(
            switchMap(() => this.getRecurringTransactions(employeeID)),
        );
    }

    saveSingle(transaction: SalaryTransaction): Observable<SalaryTransaction> {
        transaction = this.prepareSubEntetiesForSave(this.removeExpandedReadOnlyEntities(transaction));
        return this.putOrPost(transaction).pipe(
            switchMap((saved) => this.Get(saved.ID, this.salaryTransactionExpands)),
        );
    }

    delete(id: number): Observable<any> {
        return this.removeTransaction(id);
    }

    getDimensionInfo(dimensionIDs: any[]): Observable<any[]> {
        const uniqueIDs: number[] = Array.from(new Set(dimensionIDs.filter((value) => value !== null))) as number[];

        return this.statisticsService.GetAllUnwrapped(
            'model=DimensionsInfo' +
                '&select=ID as ID,DimensionsID as DimensionsID,Dimension5Name as Dimension5Name,Dimension6Name as Dimension6Name,' +
                'Dimension7Name as Dimension7Name,Dimension8Name as Dimension8Name,Dimension9Name as Dimension9Name,' +
                'Dimension10Name as Dimension10Name,Dimension5Number as Dimension5Number,Dimension6Number as Dimension6Number,' +
                'Dimension7Number as Dimension7Number,Dimension8Number as Dimension8Number,Dimension9Number as Dimension9Number,' +
                'Dimension10Number as Dimension10Number,ProjectName as ProjectName,ProjectNumber as ProjectNumber,' +
                'DepartmentName as DepartmentName,DepartmentNumber as DepartmentNumber' +
                (uniqueIDs.length ? `&filter=DimensionsID in (${uniqueIDs})` : ''),
        );
    }

    private putOrPost(transaction: SalaryTransaction): Observable<SalaryTransaction> {
        return transaction.ID ? this.Put(transaction.ID, transaction) : this.Post(transaction);
    }

    private prepareSubEntetiesForSave(salaryTransaction: SalaryTransaction): SalaryTransaction {
        if (salaryTransaction.Supplements) {
            salaryTransaction.Supplements.filter((supplement: SalaryTransactionSupplement) => !supplement.ID).forEach(
                (supplement: SalaryTransactionSupplement) => {
                    supplement['_createguid'] = this.getNewGuid();
                },
            );
        }

        if (salaryTransaction.Dimensions && !salaryTransaction.DimensionsID) {
            if (
                Object.keys(salaryTransaction.Dimensions)
                    .filter((x) => x.indexOf('ID') > -1)
                    .some((key) => salaryTransaction.Dimensions[key])
            ) {
                salaryTransaction.Dimensions['_createguid'] = this.getNewGuid();
            }
        }
        return salaryTransaction;
    }

    private removeExpandedReadOnlyEntities(transaction: SalaryTransaction): SalaryTransaction {
        transaction.Wagetype = null;
        transaction.employment = null;
        transaction.VatType = null;
        return transaction;
    }

    private async runSavingObservablesOneByOne(saveObservables: Observable<SalaryTransaction>[]) {
        const transactions: SalaryTransaction[] = [];
        for (let i: number = 0; i < saveObservables.length; i++) {
            const result = await saveObservables[i].toPromise();
            transactions.push(result);
        }
        return transactions;
    }

    public saveSalaryTransaction(trans: SalaryTransaction): Observable<SalaryTransaction> {
        return trans.ID ? super.Put(trans.ID, trans) : super.Post(trans);
    }

    public completeTrans(trans: SalaryTransaction): Observable<SalaryTransaction[]> {
        return super.ActionWithBody(null, trans, 'complete-trans', RequestMethod.Post);
    }
    public createRecurringTransactionsFromTemplate(transactions: SalaryTransaction[]): Observable<SalaryTransaction[]> {
        return super.ActionWithBody(
            null,
            transactions,
            'create-recurring-transactions-from-template',
            RequestMethod.Post,
        );
    }

    public completeTransactions(transactions: SalaryTransaction[]): Observable<SalaryTransaction[]> {
        return super.ActionWithBody(null, transactions, 'complete-transactions', RequestMethod.Post);
    }

    public removeTransaction(id: number) {
        return super.Remove(id);
    }

    public updateDataSource(source: SalaryTransaction[], transes: SalaryTransaction[]): SalaryTransaction[] {
        return transes.length > 1 ? [...source, ...transes.filter((x, i) => i !== 0)] : source;
    }

    public updateFromEmployments(employmentIDs: number[]): Observable<SalaryTransaction[]> {
        return super.ActionWithBody(null, employmentIDs, 'update-from-employments');
    }

    public calculateSupplementaryPayment(payrollruns: string): Observable<SalaryTransaction[]> {
        return super.Action(null, 'calculate-supplementary-payment', payrollruns, RequestMethod.Get);
    }

    public isReccuringDeletedOnAnyPayroll(id: number): Observable<boolean> {
        return super.Action(id, 'is-recurring-transaction-deleted', null, RequestMethod.Get);
    }

    public isReccuringDeletedForEmployment(employmentID: number): Observable<boolean> {
        const parameters = `employmentID=${employmentID}`;
        return super.Action(null, 'is-recurring-transaction-deleted', parameters, RequestMethod.Get);
    }

    public hasCarRateConstantsForYear(year: number): Observable<boolean> {
        const parameters = `year=${year}`;
        return super.Action(null, 'has-carrate-constants-for-year', parameters, RequestMethod.Get);
    }

    public updateRecurringCarTransaction(year: number): Observable<SalaryTransaction[]> {
        const parameters = `year=${year}`;
        return super.Action(null, 'update-recurring-car-transactions', parameters, RequestMethod.Post);
    }

    public importSalaryTransactions(
        fileID: number,
        payrollrunID: number,
        importType: number,
        externalReference: string,
    ): Observable<SalaryTransaction[]> {
        return super.PostAction(
            null,
            'import-salary-transactions',
            `fileID=${fileID}&payrollRunID=${payrollrunID}&importType=${importType}&externalReference=${externalReference}`,
        );
    }

    public deleteImportedSalaryTransactions(payrollrunID: number, importReferenceLogID: number): any {
        return super.DeleteAction(
            null,
            'delete-imported-salary-transactions',
            `payrollRunID=${payrollrunID}&importReferenceLogID=${importReferenceLogID}`,
        );
    }

    public fillInRowmodel(rowModel: SalaryTransaction, trans: SalaryTransaction) {
        rowModel['Rate'] = trans.Rate;
        rowModel['Text'] = trans.Text;
        rowModel['Sum'] = trans.Sum;
        rowModel['Amount'] = trans.Amount;

        return rowModel;
    }

    public getTemplateFile(): Observable<Blob> {
        return this.http
            .usingBusinessDomain()
            .asGET()
            .withEndPoint(`salarytrans?action=import-salary-transactions-template`)
            .send({ responseType: 'blob' })
            .pipe(map((res) => new Blob([res.body])));
    }
}
